<template>
<div>
  <div v-if="!auth.authenticated && authstate.loading === false" class="container">
            <!-- <div> Auth state? : {{authstate.loading}} </div> -->
          <div> <p>Login with your Discord Credentials to view the "HeatMapFX Levels"</p> </div>
          <div> <p>If you are a Paid Member, login for basic access</p> </div>
          <div class="btn btn-dark btn-lg active" v-on:click="auth.loginWithRedirect()" :disabled="auth.authenticated">Login</div>
          <hr>
        <!-- <div> Auth Level? : {{authLevel}} </div> -->
        <div > <p>If you are NOT a Paid Member, start your trial here:</p></div>
        <div>
          <a href="https://launchpass.com/heatmapfx/paid-members" class="btn btn-secondary btn-lg active" role="button">
          Start your HeatMapFx Paid Trial Here</a>
        </div>
        <hr>
 </div>
        <div v-if="authLevel === 0">
        <div > This section is for paid members only.  To access the paid data please sign up here:</div>
        <div class="row">
          <a href="https://launchpass.com/heatmapfx/paid-members" class="btn btn-secondary btn-lg active" role="button">
          Start your HeatMapFx Paid Trial Here</a>
        </div>
        </div>
    <div v-if="auth.authenticated && authLevel === 1 || authLevel === 2 || authLevel === 3 || authLevel === 4">
      <!-- <div> Auth state? : {{authstate}} </div> -->
      <div class="row selectName"> Select a TimeFrame </div>
         <div class="row selectBoxPad">
            <select class="select" v-model="timex">
               <option v-for="item in timefrs" v-bind:key="item.timefr" :value="item.timefr">{{item.timefr}}</option>
              </select>                      
             </div>
    <!-- <div v-for="item in iplot" v-bind:key="item.index">  -->
      <div v-if="timex === '20 Minutes'">
        <div>{{selectedTf}}</div>
        <div v-for="item in iplot" v-bind:key="item.index">    
            <img class="img-fluid" v-bind:src="'data:image/jpeg;base64,'+item" />
        </div>
      </div>
      <div v-if="timex === 'Daily'">
         <div>{{selectedTf}}</div>
        <div v-for="item in iDailyPlot" v-bind:key="item.index">    
            <img class="img-fluid" v-bind:src="'data:image/jpeg;base64,'+item" />
        </div>
      </div>
      <div v-if="timex === '20 Minute Order Book'">
        <div>{{selectedTf}}</div>
        <div v-for="item in iplotOrderBook" v-bind:key="item.index">    
            <img class="img-fluid" v-bind:src="'data:image/jpeg;base64,'+item" />
        </div>
      </div>
      <div v-if="timex === 'Daily Order Book'">
        <div>{{selectedTf}}</div>
        <div v-for="item in iDailyPlotOrderBook" v-bind:key="item.index">    
            <img class="img-fluid" v-bind:src="'data:image/jpeg;base64,'+item" />
        </div>
      </div>
      <div v-if="timex === 'Candle Chart'">
        <!-- <div>{{selectedTf}}</div> -->
        <div class="row">
          <div class="col">
          <!-- <div v-for="item in iDailyPlotOrderBook" v-bind:key="item.index">     -->
            <apexchart type="candlestick" :options="chartOptions2" :series="ivuechartdata"></apexchart>
            <!-- {{ivuechartdata}} -->
              <!-- <img class="img-fluid" v-bind:src="'data:image/jpeg;base64,'+item" /> -->
          </div>
          <div class="col"></div>
        </div>
      </div>
</div>
</div>
</template>
<script>
import axios from 'axios'
import VueApexCharts from "vue3-apexcharts";
import { AuthenticationState } from 'vue-auth0-plugin';
import { filteredoobFunc,extractBucketPrices,yAno } from '../functions';
export default {
  inject: ['auth'],
  components: {
          apexchart: VueApexCharts,
        },
  data: function() {
    return {
          timefrs:[ 
                    {timefr: "20 Minutes"},
                    {timefr: "Daily"},
                    {timefr: "20 Minute Order Book"},
                    {timefr: "Daily Order Book"},
                    {timefr: "Candle Chart"}
                  ],
          timex:{},
          iplot:null,
          iDailyPlot:null,
          iplotOrderBook: null,
          iDailyPlotOrderBook: null,
          ibetatesters: null,
          ispecialtesters: null,
          itboneUsers: null,
          ipaidMembers: null,
          ipremiumMembers: null,
          authstate: AuthenticationState,
          isinbeta: false,
          isinspecial: false,
          isinTbone: false,
          isinpaid: false,
          isinprem: false,
          ivuechartdata: null,
          rawChartData: null,
          ioobBuys: null,
          xoobs: null,
          // series: [{data: this.ivuechartdata}],
          //test
          chartOptions: {
		chart: {
			type: 'candlestick',
      // zoom: {enabled:true,
              // type:"xy"}
			height: 100
		},
		title: {
			text: 'CandleStick Chart',
			align: 'left'
		},
		xaxis: {
      labels: {
        show: false
      },
			type: 'numeric'
		},
		yaxis: {
      min:this.YMin,
      max:this.YMax,
      opposite:true,
			tooltip: {
				enabled: true
			}
		},
    annotations: {
        yaxis: [
          {
            y: this.YMax,
            strokeDashArray: 0,
            borderColor: '#00E396',
            label: {
              borderColor: '#00E396',
              style: {
                color: '#fff',
                background: '#00E396',
              },
              text: 'Line 1',
            },
          },
          {
            y: this.YMin,
            strokeDashArray: 0,
            borderColor: '#FF4560',
            label: {
              borderColor: '#FF4560',
              style: {
                color: '#fff',
                background: '#FF4560',
              },
              text: this.YMin,
            },
          },
        ],
      }
	}, //
    } 
    },
  methods: {
    plot: function(){
      axios.get(this.$apihostname+'/imgs')
      .then(response => response.data)
      .then(data => {
         this.iplot = data
        // console.log(data)
        // console.log(data[1][0])
      })
    },
    plot_TW_OrderBook: function(){
      axios.get(this.$apihostname+'/imgsOOB')
      .then(response => response.data)
      .then(data => {
         this.iplotOrderBook = data
        // console.log(data)
        // console.log(data[1][0])
      })
    },
    DailyPlot: function(){
      axios.get(this.$apihostname+'/DailyImgs')
      .then(response => response.data)
      .then(data => {
         this.iDailyPlot = data
        // console.log(data)
        // console.log(data[1][0])
      })
    },
    DailyPlot_OOB: function(){
      axios.get(this.$apihostname+'/DailyImgsOOB')
      .then(response => response.data)
      .then(data => {
         this.iDailyPlotOrderBook = data
        // console.log(data)
        // console.log(data[1][0])
      })
    },
    vueChartData: function(){
      axios.get(this.$apihostname+'/vueChartData20min')
      // .then(response => response)
      .then(data => {
         this.ivuechartdata = [data.data.EUR_USD] // this works
        // this.ivuechartdata = data
        this.rawChartData = data
        // this.ivuechartdata = [{'data':[data.data.EUR_USD]}]
        // console.log(data)
        // console.log([data])
      })
    },
    oobBuys: function(){
      axios.get(this.$apihostname+'/bsOrders')
      //.then(response => {this.ioobBuys = _.groupBy(response.data)})
      .then(response => response.data)
      .then(data => {
        this.ioobBuys = data
        this.xoobs = {'datay':data}
      })
        }
    ,
    betatesters: function(){
      axios.get(this.$apihostname+'/np/betatesters')
      .then(response => response.data)
      .then(data => {
        this.ibetatesters = data
        this.isinbeta = data.includes(this.auth.user?.sub.slice(15,33))
        })
      },
    specialtesters: function(){
      axios.get(this.$apihostname+'/np/specialtesting')
      .then(response => response.data)
      .then(data => {
        this.ispecialtesters = data
        this.isinspecial = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    paidMembers: function(){    /// Paid Basic Members
      axios.get(this.$apihostname+'/np/paidMembers')
      .then(response => response.data)
      .then(data => {
        this.ipaidMembers = data
        this.isinpaid = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    premiumMembers: function(){    /// Premium Members
      axios.get(this.$apihostname+'/np/premiumMembers')
      .then(response => response.data)
      .then(data => {
        this.ipremiumMembers = data
        this.isinprem = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    tboneUsers: function(){    /// tbone users
      axios.get(this.$apihostname+'/np/tboneUsers')
      .then(response => response.data)
      .then(data => {
        this.itboneUsers = data
        this.isinTbone = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
  },
  created(){
    this.plot()
    this.plot_TW_OrderBook()
    this.DailyPlot()
    this.DailyPlot_OOB()
    this.vueChartData()
    this.oobBuys()
    this.betatesters()
    this.specialtesters()
    this.paidMembers()
    this.premiumMembers()
    this.tboneUsers()
    
  },
    mounted(){
    const imgChartSelected = localStorage.getItem('imgChartSelected')
    this.timex = imgChartSelected ? imgChartSelected : {}
    this.interval = setInterval(() => {
        this.plot()
        this.plot_TW_OrderBook()
        this.DailyPlot()
        this.DailyPlot_OOB()
        this.vueChartData()
        this.oobBuys()
        this.betatesters()
    this.specialtesters()
    this.paidMembers()
    this.premiumMembers()
    this.tboneUsers()
    }, 30000 );
  },
   watch: {
    timex: {
      deep: true,
      handler(timex) {
        localStorage.setItem('imgChartSelected', timex)
      }
    }
  },
  computed: {
     selectedTf: function(){
      // console.log(this.timex)
      return this.timex
      // return _(this.ioobBuys).groupBy(this.ioobBuys => this.ioobBuys.dir)
        },
        authLevel: function() {
          //  const authp = injectAuth();
            if(
               this.isinspecial === true                
               ){
             return 4
               }
            else if(
               this.isinbeta === true
               ){
              return 3
            }
            else if(
               this.isinprem === true        
               ){
              return 2
            }
            else if(
               this.isinpaid  === true      
               ){
              return 1
            }
            else if(this.isinTbone === true || this.isinpaid === true){
              return 0
            }
            else return 5
        },
        YMin: function(){
          // Extract all the y arrays from the data object
          const yValues = this.rawChartData.data.EUR_USD.data.map(datapoint => datapoint.y)
          // const yValues = this.ivuechartdata.data.map(datapoint => datapoint.y)
          // Flatten the arrays of y values into a single array
          const flattenedYValues = [].concat(...yValues).filter(Number.isFinite)
          // Convert the strings to numbers and find the minimum value
          // console.log(flattenedYValues.filter(Number.isFinite))
          // console.log(Math.min(...flattenedYValues.map(Number).filter(Number.isFinite)))
          // Math.min(...flattenedYValues.map(Number).filter(Number.isFinite))
          return Math.min(...flattenedYValues.map(Number))
        },
        YMax: function(){
          // Extract all the y arrays from the data object
          const yValues = this.rawChartData.data.EUR_USD.data.map(datapoint => datapoint.y)
          // Flatten the arrays of y values into a single array
          const flattenedYValues = [].concat(...yValues)
          // Convert the strings to numbers and find the minimum value
          console.log(Math.max(...flattenedYValues.map(Number)))
          return Math.max(...flattenedYValues.map(Number))
        },
        oobData: function(){
          const a = this.ioobBuys
          return a
        },
        chartOptions2: function(){
          return {
            chart: {
              type: 'candlestick',
              zoom: {enabled:true,
                      type:"xy"},
                      tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
        },
              toolbar: {
                        show: true,
                        },
                selection: {
                enabled: true
              },
              // height: 'auto',
              // width: 'auto'
            },
            title: {
              text: 'CandleStick Chart',
              align: 'left'
            },
            xaxis: {
              labels: {
                show: false
              },
              type: 'numeric'
            },
            yaxis: {
              min: this.YMin - 0.0100,
              max:this.YMax + 0.0100,
              opposite:true,
              tooltip: {
                enabled: true
              }
            },
            annotations: {
                yaxis: [
                  yAno(this.bucketPrices[0].bucketsPrice),
                  yAno(this.bucketPrices[1].bucketsPrice),
                  yAno(this.bucketPrices[2].bucketsPrice),
                  yAno(this.bucketPrices[3].bucketsPrice),
                  yAno(this.bucketPrices[4].bucketsPrice),
                  yAno(this.bucketPrices[5].bucketsPrice),
                  yAno(this.bucketPrices[6].bucketsPrice),
                  yAno(this.bucketPrices[7].bucketsPrice),
                  yAno(this.bucketPrices[8].bucketsPrice),
                  yAno(this.bucketPrices[9].bucketsPrice)
                ],
              }
        }},
        filteredoob: function(){
            return filteredoobFunc(this.xoobs,"EUR_USD")
        },
        bucketPrices: function(){
          return extractBucketPrices(this.filteredoob)
        },
  }
}; 
</script>
<style scoped>
a {
    text-decoration: none;
    color: #4aa9e9;
}
.select {
  width: 300px;
  /* display: inline-block; */
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 2px rgb(204, 204, 204);
  transition: all .5s ease;
  position: relative;
  font-size: 14px;
  color: #474747;
  height: 100%;
  text-align: left;
  cursor: pointer;
  display: block;
  padding: 5px;
  font-size: 13px;
  color: #888;
  cursor: pointer;
  transition: all .3s ease-in-out;
  float: right;
  line-height: 20px;
  padding-left: 0.5rem;
}
.select:hover {
    box-shadow: 0 0 4px rgb(204, 204, 204)
}
.selectName{
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: center;
    font-size: 12px;
    /* display: inline-block; */
}
.selectBoxPad{
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

</style>
